import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { Download } from '../download'

const Do = () => {
	return (
		<L10nProvider>
			<Download />
		</L10nProvider>
	)
}

export default Do;
